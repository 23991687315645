import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const UrlReference = makeShortcode("UrlReference");
const Youtube = makeShortcode("Youtube");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`彼は背後にひそかな足音を聞いた。`}<a parentName="p" {...{
          "href": "/"
        }}>{`それはあ`}</a>{`まり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。 彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。`}</p>
    </Intro>
    <Body mdxType="Body">
      <div style={{
        background: 'orange'
      }}>p</div>
      <p>{`彼は背後にひそか`}<a parentName="p" {...{
          "href": "https://xxxxexample.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`な足音を聞いた`}</a>{`。それは`}<a parentName="p" {...{
          "href": "https://example.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`な足音を聞いた`}</a>{`あまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街`}<em parentName="p">{`灯のお粗末な港`}</em>{`街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に`}<strong parentName="p">{`無数にいる法監視役`}</strong>{`で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。 彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。`}<inlineCode parentName="p">{`display`}</inlineCode>{`が`}<inlineCode parentName="p">{`block`}</inlineCode>{`だろう`}</p>
      <div style={{
        background: 'orange'
      }}>ul</div>
      <ul>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}
          <ul parentName="li">
            <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}
              <ul parentName="li">
                <li parentName="ul">{`彼は背後にひそかな足音を聞いた`}</li>
                <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
                <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}
          <ul parentName="li">
            <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
            <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
            <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
          </ul>
        </li>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ul">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
      </ul>
      <div style={{
        background: 'orange'
      }}>ol</div>
      <ol>
        <li parentName="ol">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ol">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}
          <ol parentName="li">
            <li parentName="ol">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
            <li parentName="ol">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
          </ol>
        </li>
        <li parentName="ol">{`彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ol">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
        <li parentName="ol">{`彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた`}</li>
      </ol>
      <div style={{
        background: 'orange'
      }}>h2</div>
      <h2 {...{
        "id": "それはあまり良い意味を示すものではない"
      }}>{`それはあまり良い意味を示すものではない`}</h2>
      <div style={{
        background: 'orange'
      }}>h3</div>
      <h3 {...{
        "id": "それはあまり良い意味を示すものではない-1"
      }}>{`それはあまり良い意味を示すものではない`}</h3>
      <div style={{
        background: 'orange'
      }}>blockquote</div>
      <blockquote>
        <p parentName="blockquote">{`彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。`}</p>
        <p parentName="blockquote">{`彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。`}</p>
        <p parentName="blockquote">{`彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。`}</p>
      </blockquote>
      <div style={{
        background: 'orange'
      }}>code</div>
      <pre {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__img`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`...`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h2`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__product-name`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`ABC-1255QR`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__description`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      The quick brown fox jumps over the lazy dog...
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__nav-list`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__nav-list-item`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`#`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Link`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`li`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`product-nav__nav-list-item`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`#`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Link`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}</code></pre>
      <div style={{
        background: 'orange'
      }}>table</div>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`ひそかな`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`彼は`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`彼は背後にひそかな足音を聞いた`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`後にひそか`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`彼は背後を聞いたは背後にひそかな足音を聞いた`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`聞いたは背後にひそかな`}</td>
          </tr>
        </tbody>
      </table>
      <div style={{
        background: 'orange'
      }}>img: imgix</div>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/05afbaa5-5aa4-44fb-a140-8bcf9fe811be_IMG_9425.jpg?auto=compress,format&fit=crop&w=1200&h=400",
          "alt": null
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/05afbaa5-5aa4-44fb-a140-8bcf9fe811be_IMG_9425.jpg?auto=compress,format&fit=crop&w=900&h=1800",
          "alt": null,
          "className": "w-1/3 mx-auto"
        }}></img></p>
      <div style={{
        background: 'orange'
      }}>img: general img</div>
      <p><img parentName="p" {...{
          "src": "https://placekitten.com/300/200",
          "alt": null
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "https://placekitten.com/1000/1000",
          "alt": null,
          "className": "w-5/6 mx-auto"
        }}></img></p>
      <div style={{
        background: 'orange'
      }}>UrlReference</div>
      <UrlReference title="BEM" url="https://en.bem.info/" mdxType="UrlReference" />
      <UrlReference title="The quick brown fox jumps over the lazy dog. " url="https://en.bem.infojoaisdjfoiajdf.asjdiofajsdf.asdjfoiajsdfoia.fjaoisdfjioasdfjioasf.jaoisdfjo/" mdxType="UrlReference" />
      <UrlReference title="The quick brown fox jumps over the lazy dog. " url="https://en.bem.infojoaisdjfoiajdf.asjdiofajsdf.asdjfoiajsdfoia.fjaoisdfjioasdfjioasf.jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/jaoisdfjo/" mdxType="UrlReference" />
      <div style={{
        background: 'orange'
      }}>Youtube</div>
      <Youtube url="https://youtu.be/UqFxK9eoZYE" mdxType="Youtube" />
    </Body>
    <Outro mdxType="Outro">
      <p>{`彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      